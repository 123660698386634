import { grey } from '@mui/material/colors'
import { createTheme } from '@mui/material/styles'

export const AtomsTheme = createTheme({
  palette: {
    primary: {
      light: '#5880b1',
      main: '#2f619e',
      dark: '#20436e',
      contrastText: '#fff'
    },
    secondary: {
      light: '#f73378',
      main: '#f50057',
      dark: '#ab003c',
      contrastText: '#1c1c1c'
    }
  }
})

export const muiGrey = grey[800]
export const borderStyle = 'solid #2f619e 0.5px'
