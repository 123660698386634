import React, { useEffect } from 'react'
import { AppProps } from 'next/app'
import { ThemeProvider } from '@mui/material'
import Head from 'next/head'
import { AtomsTheme } from 'src/style/muiTheme'
import '../style/globals.css'

const CustomApp = ({ Component, pageProps }: AppProps): JSX.Element => {
  useEffect(() => {
    const jssStyles: Element | null = document.querySelector('#jss-server-side')
    jssStyles?.parentElement?.removeChild(jssStyles)
  }, [])

  return (
    <ThemeProvider theme={AtomsTheme}>
      <Head>
        <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1" />
      </Head>
      <Component {...pageProps} />
    </ThemeProvider>
  )
}

export default CustomApp
